<template>
  <b-row class="justify-content-center">
    <b-col lg="10">
      <ValidationObserver v-slot="{ handleSubmit }" ref="loginForm">
        <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(login)">
          <div>
            <b-row>
              <b-col lg="12">
                <country-code
                    :validate="'required|numeric|min:9|max:11'"
                    :placeholder="$t('auth.phoneNumber')"
                    :name="$t('auth.phoneNumber')"
                    :label="$t('auth.phoneNumber')"
                    v-model="userInfo.user"
                    id="phone-code-primary_phone_number"
                    @onSelect="onSelect"
                />
              </b-col>
              <b-col lg="12">
                <input-form
                    class="mb-3"
                    v-model="userInfo.password"
                    :validate="'required'"
                    :placeholder="$t('auth.password')"
                    :name="$t('auth.password')"
                    type="password"
                    :label="$t('auth.password')"
                />
              </b-col>
            </b-row>
            <span class="font-size-18 text-primary d-flex justify-content-end  mb-0">
                <router-link :to="{name: 'forgotPassword'}" class="link-forget ">{{ $t('auth.forgetPassword') }}</router-link>
              </span>
          </div>
          <div class="d-flex flex-column mt-2">
            <div class="d-flex align-items-center justify-content-center" >
              <b-button class="text-white font-size-20" variant="primary" type="submit" v-if="!loadingLoginButton">
                <span class="text-white px-4 py-1 font-size-18">{{$t('auth.logIn')}} </span>
              </b-button>
              <b-button v-else class=" text-white px-5 py-2" variant="primary" disabled>
                <spinner-loading text="checking"/>
              </b-button>
            </div>

            <div  class="d-flex justify-content-center mt-2">
              <router-link tag="p" class="text-center mt-2 font-size-16 font-weight-bold text-primary cursor-pointer" :to="{name: 'registration'}">{{$t('auth.createNewAccount') }}</router-link>
<!--              <router-link :to="{name: 'registration'}">-->
<!--                <b-button class="text-white py-2" variant="primary">-->
<!--                  <span class="font-size-18 ">{{$t('auth.createNewAccount') }}</span>-->
<!--                </b-button>-->
<!--              </router-link>-->
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>
<script>
import { core } from '@/config/pluginInit'
import authService from '../services/auth.js'
import firebase from '@/mixins/firebaseMixins'
import marketplaceServices from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  mixins: [firebase],
  data () {
    return {
      // loadingCheckingButton: false,
      loadingLoginButton: false,
      userInfo: {
        user: '',
        password: '',
        country_code: ''
      },
      submitCheckingForm: false,
      // correctId: false,
      showSignUp: false
    }
  },
  mounted () {
    // core.index()
  },
  watch: {
    'userInfo.user' () {
      this.submitCheckingForm = false
    }
  },
  methods: {
    login () {
      this.loadingLoginButton = true
      authService.login(this.userInfo).then(res => {
        console.log(res)
        localStorage.setItem('userInfo', JSON.stringify(res.data.data))
        localStorage.setItem('userToken', JSON.stringify(res.data.meta.token))
        core.showSnackbar('success', 'أهلا ' + res.data.data.user.name)
        this.$store.dispatch('setUserInfo', res.data.data.user)
        this.$store.dispatch('setUserToken', JSON.stringify(res.data.meta.token))
        this.updateCart()
        this.$router.push('/')
      }).catch(err => {
        if (err.response.status === 401) {
          localStorage.setItem('registrationData', JSON.stringify(this.userInfo))
          this.$router.push({
            name: 'otb',
            params: {
              phoneNumber: this.userInfo.user, // 0566502430
              country_code: this.userInfo.country_code
            }
          })
        }
      })
        .finally(() => {
          this.loadingLoginButton = false
        })
    },
    onSelect (data) {
      this.userInfo.country_code = data
    },
    updateCart () {
      if (localStorage.getItem('cart')) {
        const allProductsInCart = []
        JSON.parse(localStorage.getItem('cart')).forEach(dataInCart => {
          allProductsInCart.push({
            store_info: { id: dataInCart.store_info.id },
            store_products: dataInCart.store_products.map(({ id, quantityInCart }) => {
              return {
                id: id,
                quantityInCart: quantityInCart
              }
            })
          })
        })
        marketplaceServices.postProductInCart({ carts: allProductsInCart }).then(res => {
          console.log('this is result', res)
        })
      }
    }
  }
}
</script>
<style>
.link-forget {
  color: #FFD89A !important;
  font-size: 20px;
  text-decoration: underline !important;
}
.testphone{
  border-bottom-right-radius: 9px !important;
  border-top-right-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
}
</style>
