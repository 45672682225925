import authServices from '@/modules/auth/services/auth'
export default {
  data () {
    return {
      idToken: null
    }
  },
  methods: {
    startOnMessageListener () {
      try {
        this.$messaging.onMessage((payload) => {
          console.info('Message received : ', payload)
          console.log(payload.notification.body)
        })
      } catch (e) {
        console.error('Error : ', e)
      }
    },
    startTokenRefreshListener () {
      try {
        this.$messaging.onTokenRefresh(async () => {
          try {
            this.idToken = await this.$messaging.getToken()
            const isLoggedIn = localStorage.getItem('userToken')
            if (isLoggedIn) {
              localStorage.setItem('fcmToken', this.idToken)
              await authServices.sendFirebase(this.idToken)
            }
          } catch (e) {
            console.error('Error : ', e)
          }
        })
      } catch (e) {
        console.error('Error : ', e)
      }
    },
    async requestPermission () {
      try {
        const permission = await Notification.requestPermission()
        console.log('GIVEN notify perms')
        console.log(permission)
      } catch (e) {
        console.error('Error : ', e)
      }
    },
    async getIdToken () {
      try {
        const isLoggedIn = localStorage.getItem('userToken')
        this.idToken = await this.$messaging.getToken()
        if (isLoggedIn) {
          localStorage.setItem('fcmToken', this.idToken)
          await authServices.sendFirebase(this.idToken)
        }
      } catch (e) {
        console.error('Error : ', e)
      }
    }
  }
}
